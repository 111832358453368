import { OrganizationJsonLd } from 'next-seo'
import { AppRoutes } from 'src/lib/routes'

const WithDefaultJsonLd: React.FC = ({ children }) => {
  return children ? <>{children}</> : (
    <OrganizationJsonLd
      name="Seek Sophie"
      url={AppRoutes.rootRoute({}).toUrl()}
      aggregateRating={{
        ratingValue: 5,
        reviewCount: 100,
      }}
    />
  )
}

export default WithDefaultJsonLd
